// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Maps from "../../blocks/maps/src/Maps";
import Integrationwithzohoanalitics from "../../blocks/integrationwithzohoanalitics/src/Integrationwithzohoanalitics";
import Verifierlist from "../../blocks/verifierlist/src/Verifierlist";
import LinkShare from "../../blocks/linkshare/src/LinkShare";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";


const routeMap = {
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Maps:{
 component:Maps,
path:"/Maps"},
Integrationwithzohoanalitics:{
 component:Integrationwithzohoanalitics,
path:"/Integrationwithzohoanalitics"},
Verifierlist:{
 component:Verifierlist,
path:"/Verifierlist"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;